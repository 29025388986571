import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface TemplateContextType {
  domain: string;
  template: string;
}

interface Props {
  children: ReactNode;
}

const TemplateContext = createContext<TemplateContextType>({
  domain: 'accelerare',
  template: 'default'
});

const TemplateProvider = (props: Props) => {

  const [domain, setDomain] = useState<string>(''); // accelerare, iq-prop, luxury-time, localhost
  const [template, setTemplate] = useState<string>(''); // default, simple, modern

  useEffect(() => {
    switch (true) {
      case window.location.origin?.includes('localhost'):
        setDomain('localhost');
        setTemplate('default');
        break;
      case window.location.origin?.includes('whitelabel'):
        setDomain('whitelabel');
        setTemplate('default');
        break;
      case window.location.origin?.includes('iq-prop'):
        setDomain('iq-prop');
        setTemplate('default');
        break;
      case window.location.origin?.includes('luxury-time'):
        setDomain('luxury-time');
        setTemplate('default');
        break;
      default:
        setDomain('accelerare');
        setTemplate('default');
        break;
    }
    // eslint-disable-next-line
  }, [window.location.origin]);

  return (
    <TemplateContext.Provider value={{
      domain,
      template
    }}>
      {props.children}
    </TemplateContext.Provider>
  );
}

export { TemplateContext, TemplateProvider };
