import React, { useState, useEffect, ReactNode } from "react";
import { StatsigAutoCapturePlugin } from '@statsig/web-analytics';
import { StatsigOptions } from '@statsig/js-client';


const StatsigClient: any = require('@statsig/react-bindings').StatsigClient;


interface StatsigContextType {
    statsig: typeof StatsigClient | null;
}

const StatsigContext = React.createContext<StatsigContextType>({
    statsig: null,
});

interface Props {
    children: ReactNode;
}

const StatsigProvider = (props: Props) => {
    const [statsig, setStatsig] = useState<typeof StatsigClient | null>(null);

    useEffect(() => {
        let env = `${process.env.REACT_APP_ENV}`;

        const sdkKey = `${process.env.REACT_APP_STATSIG_CLIENT_KEY}`;
        const options: StatsigOptions = {
            plugins: [
              new StatsigAutoCapturePlugin(),
            ],
            environment: { tier: env }
        };
        const client = new StatsigClient(sdkKey,
            {

            },
            options
        );
        client.initializeAsync();
        setStatsig(client);
        // eslint-disable-next-line
    }, []);

    const getProvider = () => {
        return {
            statsig: statsig,
        }
    }

    return (
        <StatsigContext.Provider value={getProvider()}>
            {props.children}
        </StatsigContext.Provider>
    );
}

export {
    StatsigContext,
    StatsigProvider
};
