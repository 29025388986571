import { useContext } from 'react';
import './App.scss';

import { TemplateContext } from './contexts/TemplateContext.tsx';
import { Template } from './templates/template.tsx';

import CssBaseline from '@mui/material/CssBaseline';

function App() {
  const { domain, template } = useContext(TemplateContext);

  return (
    <div className="app-container">
      <CssBaseline />
      {<Template domain={domain} template={template} />}
    </div>
  );
}

export default App;
