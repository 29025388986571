import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { TemplateProvider } from './contexts/TemplateContext.tsx';
import { StatsigProvider } from './contexts/StatsigContext.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <TemplateProvider>
        <StatsigProvider>
            <App />
        </StatsigProvider>
    </TemplateProvider>
);