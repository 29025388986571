// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --whiteBase: rgba(255, 255, 255, 1);
  --greyBase: rgba(112, 115, 118, 1);
  --blackBase: rgba(0, 0, 0, 1);
  --primaryBase: rgba(14, 20, 54, 1);
  --primaryLighter1: rgba(38, 43, 74, 1);
  --primaryLighter2: rgba(62, 66, 94, 1);
  --primaryLighter3: rgba(86, 90, 114, 1);
  --primaryLighter4: rgba(110, 114, 134, 1);
  --secondaryBase: rgba(36, 213, 148, 1);
  --secondaryLighter1: rgba(79, 221, 169, 1);
  --secondaryLighter2: rgba(123, 229, 190, 1);
  --secondaryLighter3: rgba(167, 237, 212, 1);
  --secondaryLighter4: rgba(211, 246, 233, 1);
  --secondaryDarker1: rgba(28, 170, 118, 1);
  --secondaryDarker2: rgba(21, 127, 88, 1);
  --secondaryDarker3: rgba(14, 85, 59, 1);
  --secondaryDarker4: rgba(7, 42, 29, 1);
  --tertiaryBase: rgba(6, 63, 76, 1);
  --tertiaryLighter1: rgba(55, 101, 111, 1);
  --tertiaryLighter2: rgba(105, 139, 147, 1);
  --tertiaryLighter3: rgba(155, 178, 183, 1);
  --tertiaryLighter4: rgba(205, 216, 219, 1);
  --primaryDarker1: rgba(121, 107, 88, 1);
  --primaryDarker2: rgba(121, 107, 88, 1);
  --primaryDarker3: rgba(121, 107, 88, 1);
  --primaryDarker4: rgba(121, 107, 88, 1);
  --tertiaryDarker1: rgba(207, 200, 192, 1);
  --tertiaryDarker2: rgba(207, 200, 192, 1);
  --tertiaryDarker3: rgba(207, 200, 192, 1);
  --tertiaryDarker4: rgba(207, 200, 192, 1);
}`, "",{"version":3,"sources":["webpack://./src/styles/_variables.module.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,kCAAA;EACA,6BAAA;EACA,kCAAA;EACA,sCAAA;EACA,sCAAA;EACA,uCAAA;EACA,yCAAA;EACA,sCAAA;EACA,0CAAA;EACA,2CAAA;EACA,2CAAA;EACA,2CAAA;EACA,yCAAA;EACA,wCAAA;EACA,uCAAA;EACA,sCAAA;EACA,kCAAA;EACA,yCAAA;EACA,0CAAA;EACA,0CAAA;EACA,0CAAA;EACA,uCAAA;EACA,uCAAA;EACA,uCAAA;EACA,uCAAA;EACA,yCAAA;EACA,yCAAA;EACA,yCAAA;EACA,yCAAA;AACJ","sourcesContent":[":root {\n    --whiteBase: rgba(255, 255, 255, 1);\n    --greyBase: rgba(112, 115, 118, 1);\n    --blackBase: rgba(0, 0, 0, 1);\n    --primaryBase: rgba(14, 20, 54, 1);\n    --primaryLighter1: rgba(38, 43, 74, 1);\n    --primaryLighter2: rgba(62, 66, 94, 1);\n    --primaryLighter3: rgba(86, 90, 114, 1);\n    --primaryLighter4: rgba(110, 114, 134, 1);\n    --secondaryBase: rgba(36, 213, 148, 1);\n    --secondaryLighter1: rgba(79, 221, 169, 1);\n    --secondaryLighter2: rgba(123, 229, 190, 1);\n    --secondaryLighter3: rgba(167, 237, 212, 1);\n    --secondaryLighter4: rgba(211, 246, 233, 1);\n    --secondaryDarker1: rgba(28, 170, 118, 1);\n    --secondaryDarker2: rgba(21, 127, 88, 1);\n    --secondaryDarker3: rgba(14, 85, 59, 1);\n    --secondaryDarker4: rgba(7, 42, 29, 1);\n    --tertiaryBase: rgba(6, 63, 76, 1);\n    --tertiaryLighter1: rgba(55, 101, 111, 1);\n    --tertiaryLighter2: rgba(105, 139, 147, 1);\n    --tertiaryLighter3: rgba(155, 178, 183, 1);\n    --tertiaryLighter4: rgba(205, 216, 219, 1);\n    --primaryDarker1: rgba(121, 107, 88, 1);\n    --primaryDarker2: rgba(121, 107, 88, 1);\n    --primaryDarker3: rgba(121, 107, 88, 1);\n    --primaryDarker4: rgba(121, 107, 88, 1);\n    --tertiaryDarker1: rgba(207, 200, 192, 1);\n    --tertiaryDarker2: rgba(207, 200, 192, 1);\n    --tertiaryDarker3: rgba(207, 200, 192, 1);\n    --tertiaryDarker4: rgba(207, 200, 192, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
