import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import '../styles/_variables.module.scss';

export const Template = ({ domain, template }: { domain: string, template: string }) => {

  const [renderTemplate, setRenderTemplate] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (domain && template) {
      console.log(`./${domain}/${template}`);
      importComponent(`./${domain}/${template}`);
      changeTitle(`${domain}`);
    }
  }, [domain, template]);

  const importComponent = async (path: string) => {
    let module: any;
    switch (path) {
      case './localhost/default':
      case './localhost/simple':
      case './localhost/modern':
        module = await import(`./localhost/default/index.tsx`);
        break;
      case './whitelabel/default':
      case './whitelabel/simple':
      case './whitelabel/modern':
        module = await import(`./whitelabel/default/index.tsx`);
        break;
      case './iq-prop/default':
      case './iq-prop/simple':
      case './iq-prop/modern':
        module = await import(`./iq-prop/default/index.tsx`);
        break;
      case './luxury-time/default':
      case './luxury-time/simple':
      case './luxury-time/modern':
        module = await import(`./luxury-time/default/index.tsx`);
        await import('./luxury-time/default/styles/_variables.module.scss');
        break;
      default:
        module = await import(`./localhost/default/index.tsx`);
        break;
    }
    setRenderTemplate(module.default);
  };

  const changeTitle = (domain: string) => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = `/templates/${domain}/favicon.ico`;
    }

    if (domain === 'iq-prop') {
      document.title = 'iQ-Prop | Find. Match. Close.';
    } else {
      document.title = domain;
    }
    

    const manifest = document.getElementById('manifest') as HTMLLinkElement;
    if (manifest) {
      manifest.href = `/templates/${domain}/manifest.json`;
    }

    const appleTouchIcon = document.getElementById('apple-touch-icon') as HTMLLinkElement;
    if (appleTouchIcon) {
      appleTouchIcon.href = `/templates/${domain}/logo192.png`;
    }
  }

  return (
    <div>
      <BrowserRouter>
        {renderTemplate && renderTemplate}
      </BrowserRouter>
    </div>
  );
};